<template>
  <div
    class="share-actions"
    @mouseleave="isShareListShown = false"
  >
    <img
      src="@/assets/img/products/share.svg"
      alt=""
      @mouseenter="isShareListShown = true"
    />
    <div
      @mouseenter="isShareListShown = true"
      :class="['share-actions-list', position]"
      v-if="isShareListShown"
    >
      <a
        class="share-actions-list__item"
        :href="`http://www.odnoklassniki.ru/dk?st.cmd=addShare&st.s=1&st._surl=${getShareUrl}`"
        target="_blank"
        ><img src="@/assets/icons/share/ok.svg"
      /></a>
      <a
        class="share-actions-list__item"
        :href="'https://vk.com/share.php?url=' + getShareUrl"
        target="_blank"
        ><img src="@/assets/icons/share/vk.svg"
      /></a>
      <a
        class="share-actions-list__item"
        :href="`https://www.facebook.com/sharer/sharer.php?u=${getShareUrl}`"
        target="_blank"
        ><img src="@/assets/icons/share/fb.svg"
      /></a>
      <a
        class="share-actions-list__item"
        :href="`https://wa.me/?text=${getShareUrl}`"
        data-action="share/whatsapp/share"
        target="_blank"
        ><img src="@/assets/icons/share/whatsapp.svg"
      /></a>
      <a
        class="share-actions-list__item"
        target="_blank"
        :href="'https://t.me/share/url?url=' + getShareUrl + '&text=Поделится с ссылкой'"
        ><img src="@/assets/icons/share/telegram.svg"
      /></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppShareActions",

  props: {
    position: {
      type: String,
      default: "right",
    },
  },

  data() {
    return {
      isShareListShown: false,
    }
  },

  computed: {
    getShareUrl() {
      return window.location.href
    },
  },
}
</script>

<style lang="scss">
.left {
  right: 0;
}
.right {
  left: 0 !important;
}
</style>
