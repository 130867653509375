<template>
  <div
    v-if="articles.length"
    class="other__articles"
  >
    <h2 class="other__articles-title">{{ $t("otherArticles") }}</h2>
    <div class="other__articles__items">
      <div
        v-for="(item, index) in articles"
        :key="index"
        class="other__articles__item"
        :class="{ big: checkItemSize(index), hidden: item.id === exclude }"
      >
        <a :href="`/useful-articles/${item.id}`">
          <div class="other__articles__content">
            <p class="other__articles__date">{{ getDate(item) }}</p>
            <h6 class="other__articles__title">{{ item.attributes.header }}</h6>
          </div>
        </a>
        <a :href="`/useful-articles/${item.id}`">
          <img
            :src="getImage(item)"
            class="other__articles__image"
          />
        </a>
      </div>
      <button
        v-if="!pagination.isLastPage"
        class="useful-articles__button"
        @click="incrementItems"
      >
        {{ $t("more") }}
      </button>
    </div>
  </div>
</template>

<script>
import { getArticles } from "@/api/articles"
export default {
  name: "OtherArticles",
  props: {
    exclude: {
      type: String,
    },
  },
  data() {
    return {
      showSeeAll: false,
      articles: [],
      pagination: {
        page: 1,
        pageSize: 5,
        isLastPage: false,
      },
      localMonth: {
        января: "қаңтар",
        февраля: "ақпан",
        марта: "наурыз",
        апреля: "сәуір",
        мая: "мамыр",
        июня: "маусым",
        июля: "шілде",
        августа: "тамыз",
        сентября: "қыркүйек",
        октября: "қазан",
        ноября: "қараша",
      },
    }
  },
  methods: {
    incrementItems() {
      this.pagination.page++
    },
    checkItemSize(e) {
      const index = e + 1
      return index % 5 === 1 || index % 5 === 2
    },
    getImage(item) {
      return this.$helpers.getAbsolutePath(item?.attributes?.image?.data?.attributes?.url)
    },
    getDate(item) {
      const dateFromStr = new Date(item.attributes.publishedAt)
      let dateRU = new Intl.DateTimeFormat("ru-Ru", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(dateFromStr)
      let date = dateRU.split(" ")
      let dateKZ = date[0] + " " + this.localMonth[date[1]] + " " + date[2]
      if (this.$i18n.locale === "kz") {
        return dateKZ
      } else {
        return dateRU
      }
    },
    getFilteredArticles(articles) {
      const filteredArticles = articles.filter((item) => {
        return item.attributes.type === "article"
      })
      return filteredArticles
    },
  },
  watch: {
    "pagination.page": {
      handler() {
        getArticles(this.pagination.page, this.pagination.pageSize, "articles", this.exclude).then(
          (res) => {
            const newArticles = this.getFilteredArticles(res.data)
            this.articles = [...this.articles, ...newArticles]
            if (this.pagination.page === res.meta.pagination.pageCount) {
              this.pagination.isLastPage = true
            } else {
              this.pagination.isLastPage = false
            }
          },
        )
      },
    },
  },
  created() {
    if (this.$isMobile()) {
      this.pagination.pageSize = 3
    }
    getArticles(this.pagination.page, this.pagination.pageSize, "articles", this.exclude).then(
      (res) => {
        this.articles = res.data
        if (this.pagination.page === res.meta.pagination.pageCount) {
          this.pagination.isLastPage = true
        } else {
          this.pagination.isLastPage = false
        }
      },
    )
  },
}
</script>

<style
  lang="scss"
  scoped
>
/* .hidden {
  display: none;
} */
</style>
