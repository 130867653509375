<template>
  <div
    class="full-articles"
    v-if="item"
  >
    <div class="container">
      <div class="recipes__nav">
        <locale-router-link
          to=""
          class="crumbs-link main-page-link"
        >
          {{ $t("home") }}
        </locale-router-link>
        <a class="crumbs-link recipes-link"> {{ $t("usefulArticles") }}</a>
      </div>
      <h2 class="articles-title small-title">{{ $t("usefulArticles") }}</h2>
      <div class="recipe">
        <div class="recipe__intro">
          <div class="recipe__title">
            <h2 class="recipe__title-title small-title">{{ item.attributes.header }}</h2>
          </div>
          <div class="date-share">
            <div class="recipe__subtitle">
              <p>{{ getDate(item) }}</p>
              <p class="recipe__subtitle-views">
                <img src="@/assets/icons/eye.svg" />&nbsp;{{ item.attributes.hits }}
              </p>
            </div>
            <div class="mobile-share">
              <app-share-actions position="left" />
            </div>
          </div>
          <img
            :src="getImage(item)"
            class="recipe__intro-image"
          />
          <p
            v-if="item.attributes.description"
            class="recipe__intro-text"
          >
            {{ item.attributes.description }}
          </p>
        </div>
        <div class="recipe__content">
          <vue-markdown
            style="white-space: pre-line"
            :source="item.attributes.text"
            :anchorAttributes="{ target: '_blank' }"
            class="recipe__content-text"
          >
            {{ item.attributes.text }}
          </vue-markdown>
        </div>
        <div class="recipe__share-box">
          <div class="recipe__share">
            <a
              target="_blank"
              :href="'https://www.facebook.com/sharer/sharer.php?u=' + getShareUrl()"
              ><img
                ref="scroll"
                class="recipe__share-icon"
                src="@/assets/icons/share/fb.svg"
            /></a>
            <a
              target="_blank"
              :href="'https://wa.me/?text=' + getShareUrl()"
              ><img
                class="recipe__share-icon"
                src="@/assets/icons/share/whatsapp.svg"
            /></a>
            <a
              target="_blank"
              :href="'https://t.me/share/url?url=' + getShareUrl() + '&text=Поделится с ссылкой'"
              ><img
                class="recipe__share-icon"
                src="@/assets/icons/share/telegram.svg"
            /></a>
            <a
              :href="'https://vk.com/share.php?url=' + getShareUrl()"
              target="_blank"
              ><img
                class="recipe__share-icon"
                src="@/assets/icons/share/vk.svg"
            /></a>
            <a
              :href="
                'http://www.odnoklassniki.ru/dk?st.cmd=addShare&st.s=1&st._surl=' + getShareUrl()
              "
              target="_blank"
              ><img
                class="recipe__share-icon"
                src="@/assets/icons/share/ok.svg"
            /></a>
          </div>
        </div>

        <other-articles :exclude="item.id" />
      </div>
    </div>
  </div>
</template>
<script lang="js">
import Vue from "vue"
//@ts-ignore
import { getArticleById } from "@/api/articles"
import VueMarkdown from "vue-markdown"

import OtherArticles from "../../components/useful-articles/OtherArticles.vue"
import AppShareActions from "@/components/common/AppShareActions.vue"

export default Vue.extend({
  components: { OtherArticles,  VueMarkdown, AppShareActions},
  name: "FullUsefulArticles",

  data() {
    return {
      item: null,
      items: [
        {
          name: "1. Сазерак (Sazerac)",
          descr:
            "Сазерак, как считается, был изобретен в середине 1800-х годов в кофейне Sazerac в Новом Орлеане. Изначально он был сделан на коньяке Sazerac de Forge et Fils. В 1873 году Бармен Леон Ламот добавил в стакан дэш абсента, подарив коктейлю новое прочтение. Это совпало с теми временами, когда французские виноградники погибали от филлоксеры, что вызвало великий французский винный кризис середины 19-го века. Результат — дефицит бренди и коньяка, основных ингредиентов оригинального Сазерака. Именно тогда в коктейле появился американский ржаной виски. Теперь большинство Сазераков содержат его (часто вместе с коньяком), а также дэш абсента или Herbsaint* (Хербсэйнт), кусочек сахара и лимонный твист. *Herbsaint (Хербсэйнт) — бренд анисового ликера, первоначально созданного как абсент-заменитель в Новом Орлеане, штат Луизиана, в 1934 году. В настоящее время выпускается компанией Sazerac.",
          img: require("@/assets/img/test.png"),
        },
      ],
      localMonth: {
        января: "қаңтар",
        февраля: "ақпан",
        марта: "наурыз",
        апреля: "сәуір",
        мая: "мамыр",
        июня: "маусым",
        июля: "шілде",
        августа: "тамыз",
        сентября: "қыркүйек",
        октября: "қазан",
        ноября: "қараша",
      },
    }
  },
  created() {
    getArticleById(this.$route.params.id).then((res) => {
      this.item = res.data
    })
  },
  methods: {
    getShareUrl() {
      return window.location.href
    },
    getImage(item) {
      return this.$helpers.getAbsolutePath(item?.attributes?.image?.data?.attributes?.url)
    },
    getDate(item) {
      const dateFromStr = new Date(item.attributes.publishedAt)
      let dateRU = new Intl.DateTimeFormat("ru-Ru", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(dateFromStr)
      let date = dateRU.split(" ")
      let dateKZ = date[0] + " " + this.localMonth[date[1]] + " " + date[2]
      if (this.$i18n.locale === "kz") {
        return dateKZ
      } else {
        return dateRU
      }
    },
  },
})
</script>

<style lang="scss">
.recipe {
  &__content {
    margin-top: 30px;
    &-text {
      word-break: break-word;
      a {
        word-break: break-all;
      }
    }
  }

  &__title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    img {
      max-width: 34px;
    }
    &-title {
      font-size: 34px;
      line-height: 100%;
      font-family: "CeraPro Bold";
      color: #2d2f32;
    }
  }
  &__subtitle {
    display: flex;
    font-size: 18px;
    line-height: 100%;
    font-family: "CeraPro Medium";
    color: #999999;
    margin: 18px 0;
    &-views {
      margin-left: 40px;
      display: flex;
    }
  }
  &__intro {
    &-image {
      width: 100%;
      border-radius: 10px;
    }
    &-text {
      margin: 40px 0;
      font-size: 18px;
      line-height: 110%;
      font-family: "CeraPro Regular";
      color: #2d2f32;
    }
  }
  &__share {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    margin-right: 40px;

    &-box {
      margin-top: 50px;
      margin-bottom: 100px;
      display: flex;
      align-items: center;
    }

    &-icon {
      margin-right: 16px;
      width: 50px;
      cursor: pointer;
    }
    &-text {
      margin-left: 22px;
      font-size: 18px;
      line-height: 110%;
      font-family: "CeraPro Regular";
      color: #2d2f32;
    }
  }
  &__content {
    &-item {
      margin: 20px 0 60px;
      &__title {
        font-size: 30px;
        line-height: 110%;
        font-family: "CeraPro Medium";
        color: #2d2f32;
        padding-bottom: 40px;
      }
      &__content {
        display: flex;
        &-main {
          font-family: "CeraPro Regular";
          font-size: 18px;
          line-height: 110%;
          color: #2d2f32;
          width: 100%;
          padding-right: 60px;
        }
        &-image {
          max-width: 450px;
          width: 100%;
        }
      }
    }
  }
}

.mobile-share {
  display: none;
}
@media (max-width: 1024px) {
  .recipe__content-item__content {
    flex-direction: column;
    &-image {
      width: 100%;
      padding-right: 60px;
      margin-top: 20px;
      max-width: unset;
    }
  }
}
@media (max-width: 670px) {
  .recipe__content-item__content {
    &-main {
      padding-right: unset;
    }
    &-image {
      padding-right: unset;
    }
  }
  .recipe__share {
    margin-right: 10px;
    a {
      img {
        width: 35px;
      }
    }
  }

  .recipe__share-box {
    margin: 40px 0;
  }

  .small-title {
    font-size: 20px;
    width: 100%;
  }
  .date-share {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mobile-share {
    display: block;
    width: 24px;
    height: 24px;
  }
  .desktop-share {
    display: none;
  }
}
@media (max-width: 768px) {
  .recipe {
    &__content {
      &-text {
        font-size: 14px !important;
      }
    }
  }
  .container {
    padding: 0 16px;
  }
}

@media (max-width: 600px) {
  .recipe__share {
    margin-right: 0;
    margin-bottom: 20px;
    a:last-child {
      img {
        margin-right: 0;
      }
    }
  }
  .recipe__share-box {
    flex-direction: column;
  }
}

@media (max-width: 500px) {
  .recipe__share {
  }
}
</style>
